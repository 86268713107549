import React from "react";
import styled from "styled-components";

export default function ProjectBox({ img, title, text, action}) {
  return (
    <Wrapper>
      {action ? (
        <Link href={action} target="_blank" rel="noopener noreferrer">
          <ImgBtn className="animate pointer">
            <img className="radius8" src={img} alt="project" />
          </ImgBtn>
        </Link>
      ) : (
        <ImgBtn className="animate pointer">
          <img className="radius8" src={img} alt="project" />
        </ImgBtn>
      )}
      <h3 className="font20 extraBold">{title}</h3>
      <p className="font13">{text}</p>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  margin-top: 30px;
  img {
    width: 100%;
    height: auto;
    margin: 20px 0;
  }
  h3 {
    padding-bottom: 10px;
  }
`;
const ImgBtn = styled.button`
  background-color: transparent;
  border: 0px;
  outline: none;
  padding: 0px;
  margin: 0px;
  :hover > img {
    opacity: 0.5;
  }
`;
const Link = styled.a`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;