import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import FWSLogo from "../../assets/img/fwsLogo.png";

export default function Contact() {

  const getCurrentYear = () => {
    return new Date().getFullYear();
  }

  return (
    <Wrapper>
      <div className="darkBg">
        <div className="container">
          <InnerWrapper className="flexSpaceCenter" style={{ padding: "15px 0" }}>
            <Link className="flexCenter animate pointer" to="home" smooth={true} offset={-80}>
              <img style={{width: "150px"}} src={FWSLogo} alt="" />
            </Link>
            <ContactInfo className="whiteColor font13" style={{display: 'flex'}}>
              Email: <a style={{marginLeft: '5px'}} href="mailto:your-email@example.com">info@foxwebservices.com</a>
            </ContactInfo>
            <ContactInfo className="whiteColor font13" style={{display: 'flex'}}>
              Phone: <a style={{marginLeft: '5px'}} href="tel:+447862273680">07862273680</a>
            </ContactInfo>

            <Link className="whiteColor animate pointer font13" to="home" smooth={true} offset={-80}>
              Back to top
            </Link>
          </InnerWrapper>
        </div>
      </div>
      <div>
        <p style={{background: 'black', textAlign: 'center', padding: '10px'}} className="whiteColor font13">Fox Web Services © {getCurrentYear()} All Rights Reserved</p>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const ContactInfo = styled.div`
  a {
    color: #ffffff;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;