import React from "react";
import styled from "styled-components";
// Components
import PricingTable from "../Elements/PricingTable";

export default function Pricing() {
  return (
    <Wrapper id="pricing">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Packages & Pricing</h1>
            <p className="font13">
            We believe in transparent pricing, offering clear and straightforward packages tailored to fit your budget.
            <br />
            No hidden fees, just honest pricing for exceptional web solutions.
            </p>
          </HeaderInfo>
          <HeaderInfo>
            <h1 style={{marginBottom: '-70px'}} className="font30 extraBold">Website Pricing</h1>
          </HeaderInfo>
          <TablesWrapper className="flexSpaceNull">
            <TableBox>
              <PricingTable
                type="basic"
                price="£40/mo"
                title="Basic"
                text="Ideal for small businesses and personal projects."
                offers={[
                  { name: "Up to 5 pages", cheked: true },
                  { name: "Basic SEO", cheked: true },
                  { name: "Responsive Design", cheked: true },
                  { name: "Hosting", cheked: true },
                  { name: "SSL Certificate", cheked: true },
                  { name: "Basic Support", cheked: true },
                ]}
                action={() => alert("clicked")}
              />
            </TableBox>
            <TableBox>
            <PricingTable
                type="standard"
                price="£60/mo"
                title="Standard"
                text="Perfect for growing businesses needing more pages & enhanced features like contact forms."
                offers={[
                  { name: "Up to 10 pages", cheked: true },
                  { name: "Enhanced SEO", cheked: true },
                  { name: "Responsive Design", cheked: true },
                  { name: "Hosting", cheked: true },
                  { name: "SSL Certificate", cheked: true },
                  { name: "Enhanced Support", cheked: true },
                  { name: "Contact Form", cheked: true },
                ]}
                action={() => alert("clicked")}
              />
            </TableBox>
            <TableBox>
            <PricingTable
                type="premium"
                price="£80/mo"
                title="Premium"
                text="For businesses looking for a fully custom website with a high number of pages and premium design elements"
                offers={[
                  { name: "Up to 50 pages", cheked: true },
                  { name: "Advanced SEO", cheked: true },
                  { name: "Responsive Design", cheked: true },
                  { name: "Hosting", cheked: true },
                  { name: "SSL Certificate", cheked: true },
                  { name: "Advanced Support", cheked: true },
                  { name: "Contact Form", cheked: true },
                  { name: "Monthly Analytics Report", cheked: true },
                  { name: "Content Management System", cheked: true },
                ]}
                action={() => alert("clicked")}
              />
            </TableBox>
          </TablesWrapper>
        </div>
        <div className="container">
          <HeaderInfo>
            <h1 style={{marginBottom: '-70px'}} className="font30 extraBold">E-Commerce Add-On</h1>
          </HeaderInfo>
          <TablesWrapper className="flexSpaceNull">
            <TableBox>
              <PricingTable
                type="small"
                price="£15/mo"
                title="Small Store"
                text="Manage up to 100 products with secure payment integration and basic inventory management."
                action={() => alert("clicked")}
              />
            </TableBox>
            <TableBox>
            <PricingTable
                type="medium"
                price="£25/mo"
                title="Medium Store"
                text="Handle up to 1,000 products with advanced inventory features, promotional tools, and enhanced security."
                action={() => alert("clicked")}
              />
            </TableBox>
            <TableBox>
            <PricingTable
                type="large"
                price="£50/mo"
                title="Large Store"
                text="Support up to 10,000 products with comprehensive e-commerce solutions, including advanced analytics and marketing integrations."
                action={() => alert("clicked")}
              />
            </TableBox>
          </TablesWrapper>
        </div>
        <div className="container">
          <HeaderInfo>
            <h1 style={{marginBottom: '-70px'}} className="font30 extraBold">Additional Services</h1>
          </HeaderInfo>
          <TablesWrapper className="flexSpaceNull">
            <TableBox>
              <PricingTable
                type="consul1"
                price="£50 p/h"
                title="Basic Consultation"
                text="Ideal for general guidance on web strategy, design and development."
                action={() => alert("clicked")}
              />
            </TableBox>
            <TableBox>
            <PricingTable
                type="consul2"
                price="£25/mo"
                title="Advanced Consultation"
                text="For in-depth analysis and personalized advice on complex web development, custom software solutions, and e-commerce strategy."
                action={() => alert("clicked")}
              />
            </TableBox>
            <TableBox>
            <PricingTable
                type="consul3"
                price="£50/mo"
                title="Premium Consultation"
                text="Comprehensive consultation covering all aspects of your digital transformation, including strategy, implementation, and ongoing support."
                action={() => alert("clicked")}
              />
            </TableBox>
          </TablesWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto
  }
`;




