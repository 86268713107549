import React from "react";
import styled from "styled-components";
// Assets
import CheckMark from "../../assets/svg/Checkmark";
import Basic from "../../assets/img/basic.png";
import Standard from "../../assets/img/standard.png";
import Premium from "../../assets/img/premium.png";
import Small from "../../assets/img/small.png";
import Medium from "../../assets/img/medium.png";
import Large from "../../assets/img/large.png";
import Consul1 from "../../assets/img/consul1.png";
import Consul2 from "../../assets/img/consul2.png";
import Consul3 from "../../assets/img/consul3.png";

export default function PricingTable({ type, price, title, text, offers, action }) {
  let getImage;

  switch (type) {
    case "basic":
      getImage = <img src={Basic} alt="Basic Plan" style={{ width: '50px' }} />;
      break;
    case "standard":
      getImage = <img src={Standard} alt="Standard Plan" style={{ width: '50px' }} />;
      break;
    case "premium":
      getImage = <img src={Premium} alt="Premium Plan" style={{ width: '50px' }} />;
      break;
    case "small":
      getImage = <img src={Small} alt="Premium Plan" style={{ width: '50px' }} />;
      break;
    case "medium":
      getImage = <img src={Medium} alt="Premium Plan" style={{ width: '50px' }} />;
      break;
    case "large":
      getImage = <img src={Large} alt="Premium Plan" style={{ width: '50px' }} />;
      break;
    case "consul1":
      getImage = <img src={Consul1} alt="Premium Plan" style={{ width: '50px' }} />;
      break;
    case "consul2":
      getImage = <img src={Consul2} alt="Premium Plan" style={{ width: '50px' }} />;
      break;
    case "consul3":
      getImage = <img src={Consul3} alt="Premium Plan" style={{ width: '50px' }} />;
      break;
    default:
      getImage = <img src={Basic} alt="Default Plan" style={{ width: '50px' }} />;
      break;
  }

  return (
    <Wrapper className="whiteBg radius8 shadow">
      <div className="flexSpaceCenter">
        {getImage}
        <p className="font30 extraBold">{price}</p>
      </div>
      <div style={{ margin: "30px 0" }}>
        <h4 className="font30 extraBold">{title}</h4>
        <p className="font13">{text}</p>
      </div>
      <div>
        {offers
          ? offers.map((item, index) => (
              <div className="flexNullCenter" style={{ margin: "15px 0" }} key={index}>
                <div style={{ position: "relative", top: "-1px", marginRight: "15px" }}>
                  {item.checked ? (
                    <div style={{ minWidth: "20px" }}>
                      <CheckMark />
                    </div>
                  ) : (
                    <div style={{ minWidth: "20px" }}></div>
                  )}
                </div>
                <p className="font20 extraBold">{item.name}</p>
              </div>
            ))
          : null}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 20px 30px;
  margin-top: 30px;
`;