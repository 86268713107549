import React from "react";
import styled from "styled-components";

export default function FullButton({ title, action, border }) {
  return (
    <Wrapper
      className="animate pointer radius8"
      onClick={action ? () => action() : null}
      border={border}
    >
      {title}
    </Wrapper>
  );
}

const Wrapper = styled.button`
  border: 1px solid ${(props) => (props.border ? "#707070" : "#0A1922")};
  background-color: ${(props) => (props.border ? "transparent" : "#0A1922")};
  width: 100%;
  padding: 15px;
  outline: none;
  color: ${(props) => (props.border ? "#707070" : "#fff")};
  font-size: 1rem;
  font-weight: 600;
  font-family: 'Khula';
  :hover {
    background-color: ${(props) => (props.border ? "transparent" : "#0B2E3E")};
    border: 1px solid #0A1922;
    color: ${(props) => (props.border ? "#0A1922" : "#fff")};
  }
`;

