import React from "react";
import styled from "styled-components";
// Components
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";
// Assets
import Mobile from "../../assets/img/mobile.png";
import Tool from "../../assets/img/tool.png";
import App from "../../assets/img/app.png";
import Page from "../../assets/img/page.png";
import Report from "../../assets/img/report.png";
import Ecommerce from "../../assets/img/ecommerce.png";
import Consulting from "../../assets/img/consulting.png";
import Cloud from "../../assets/img/cloud-server.png";

export default function Services() {
  return (
    <Wrapper id="services">
      <div className="whiteBg" style={{ padding: "80px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Services</h1>
            <p className="font13">
            At Fox Web Services, we offer a full range of web solutions including captivating web design, robust e-commerce 
              platforms, seamless maintenance, and expert consulting.
              <br />
              Our services are designed to boost your online presence, ensuring a user-friendly and secure experience that drives business growth.
              <br />
              <br />
              Contact us today to transform your digital vision into reality!
            </p>
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
            <img className="serviceIcon" src={Page} alt="" />
              <ServiceBox
                title="Web Development"
                subtitle="Create stunning, user-friendly websites tailored to your needs. Our services ensure your site is mobile-responsive, fast, and meets modern web standards."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <img className="serviceIcon" src={Ecommerce} alt="" />
              <ServiceBox
                title="E-Commerce"
                subtitle="Build and manage secure, scalable online stores. We handle everything from payment integration to inventory management, making online selling easy and effective."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
            <img className="serviceIcon" src={Tool} alt="" />
              <ServiceBox
                title="Web Maintenance"
                subtitle="Keep your website updated, secure, and running smoothly with our regular maintenance services. We handle updates, security patches, and content management."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
            <img className="serviceIcon" src={Consulting} alt="" />
              <ServiceBox
                title="Consulting Services"
                subtitle="Get expert advice on digital strategy to enhance your online presence. Our consultants provide actionable insights to help you achieve your business goals." />
            </ServiceBoxWrapper>
          </ServiceBoxRow>

          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
            <img className="serviceIcon" src={App} alt="" />
              <ServiceBox
                title="Custom Software"
                subtitle="Develop custom web applications and mobile apps that fit your unique business needs. We offer tailored, scalable solutions for complex requirements."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <img className="serviceIcon" src={Cloud} alt="" />
              <ServiceBox
                title="Hosting & Domains"
                subtitle="Ensure your website’s reliability with our hosting solutions. We provide domain registration, professional email hosting, and secure server management."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
            <img className="serviceIcon" src={Report} alt="" />
              <ServiceBox
                title="Analytics & Reporting"
                subtitle="Gain insights into your website’s performance with our analytics services. We track key metrics and provide detailed reports to help you optimize your digital strategy."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
            <img className="serviceIcon" src={Mobile} alt="" />
              <ServiceBox
                title="Much more"
                subtitle="In addition to our core services, we provide everything you need to succeed online and much more. Discover how we can support your digital journey with our extensive range of services!"
              />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
          <BtnWrapper>
            <FullButton title="Get In Touch" />
          </BtnWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
