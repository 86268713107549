import React, { useState } from "react";
import styled from "styled-components";

export default function Contact() {
  const [formData, setFormData] = useState({
    fname: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Wrapper id="contact">
      <div className="lightBg">
        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center' }} className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Get in touch</h1>
            <p className="font13">
              Ready to transform your digital presence? Whether you have questions, need a quote, or want to discuss your next big project, we’re here to help!
              <br />
              Contact us today and let’s start building something great together. Reach out via phone, email, or fill out our contact form.
              <br />
              We look forward to hearing from you!
            </p>
          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px", justifyContent: 'center' }}>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <Form action="https://formspree.io/f/mzzpbpyq" method="POST">
                <label className="font13">First name:</label>
                <input
                  type="text"
                  id="fname"
                  name="fname"
                  className="font20 extraBold"
                  value={formData.fname}
                  onChange={handleChange}
                />
                <label className="font13">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="font20 extraBold"
                  value={formData.email}
                  onChange={handleChange}
                />
                <label className="font13">Subject:</label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  className="font20 extraBold"
                  value={formData.subject}
                  onChange={handleChange}
                />
                <label className="font13">Message:</label>
                <textarea
                  rows="4"
                  cols="50"
                  type="text"
                  id="message"
                  name="message"
                  className="font20 extraBold"
                  value={formData.message}
                  onChange={handleChange}
                />
                <SumbitWrapper className="flex">
                  <ButtonInput type="submit" value="Send Message" className="pointer animate radius8 submitButton" style={{ maxWidth: "220px" }} />
                </SumbitWrapper>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #0A1922;
  background-color: #0A1922;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #0B2E3E;
    border: 1px solid #0A1922;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;